import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { KoreaJpLayout } from "@/components/layout"
import SEO from "../../components/seo"
import TabsComponent from "./tab"
import BannerComponent from "./banner"
import * as Styles from "./index.module.scss"
import { KoreaJpForm } from "../../components/form/KoreaJpForm"

/* ==================================================
  画像の読み込み
================================================== */
import arrow from "../../images/korea/message/arrow.svg"
import tamKoreaLogo from "../../images/korea/jumbotron/kantam_koreajp.png"
import tamKoreaLogoSp from "../../images/korea/jumbotron/kantam_koreajp_sp.png"

import messageImg01 from "../../images/korea/message/illust01_koreajp.png"
import messageImg01w from "../../images/korea/message/illust01_koreajp.webp"
import messageImg01Sp from "../../images/korea/message/illust01_koreajp_sp.png"
import messageImg01Spw from "../../images/korea/message/illust01_koreajp_sp.webp"

import marketGraph01 from "../../images/korea/market/graph01_koreajpjp.png"
import marketTable01 from "../../images/korea/market/table01_koreajpjp.png"
import marketTable01w from "../../images/korea/market/table01_koreajpjp.webp"

import companyHeading from "../../images/korea/company/company_kr_koreajp.svg"
import companyHeadingSp from "../../images/korea/company/company_kr_koreajp_sp.svg"
import companyImg from "../../images/korea/company/company_koreajp.svg"
import companyImgSp from "../../images/korea/company/company_koreajp_sp.svg"

import ServiceIcon01 from "../../images/korea/plan/icon_research_koreajp.svg"
import ServiceIcon02 from "../../images/korea/plan/icon_strategy_koreajp.svg"
import ServiceIcon03 from "../../images/korea/plan/icon_sns_koreajp.svg"
import ServiceIcon04 from "../../images/korea/plan/icon_create_koreajp.svg"
import ServiceIcon05 from "../../images/korea/plan/icon_influencer_koreajp.svg"
import ServiceIcon06 from "../../images/korea/plan/icon_advertise_koreajp.svg"

import resultCompany01 from "../../images/korea/result/company01.svg"
import resultCompany02 from "../../images/korea/result/company02.svg"
import resultCompany03 from "../../images/korea/result/company03.svg"
import resultCompany04 from "../../images/korea/result/company04.svg"
import resultCompany05 from "../../images/korea/result/company05.svg"
import resultCompany06 from "../../images/korea/result/company06.svg"
import resultCompany07 from "../../images/korea/result/company07.svg"
import resultCompany08 from "../../images/korea/result/company08.svg"
import resultCompany09 from "../../images/korea/result/company09.svg"
import resultCompany10 from "../../images/korea/result/company10.svg"
import resultCompany11 from "../../images/korea/result/company11.svg"
import resultCompany12 from "../../images/korea/result/company12.svg"
import resultCompany13 from "../../images/korea/result/company13.svg"
import resultCompany14 from "../../images/korea/result/company14.svg"
import resultCompany15 from "../../images/korea/result/company15.svg"
import resultCompany16 from "../../images/korea/result/company16.svg"
import resultCompany17 from "../../images/korea/result/company17.svg"
import resultCompany18 from "../../images/korea/result/company18.svg"
import resultCompany19 from "../../images/korea/result/company19.svg"
import resultCompany20 from "../../images/korea/result/company20.svg"
import resultCompany21 from "../../images/korea/result/company21.svg"

// companyロゴをオブジェクトに格納
const supportLogos = [
  { src: resultCompany01, alt: "KYOCERA Logo" },
  { src: resultCompany02, alt: "Panasonic Logo" },
  { src: resultCompany03, alt: "Nestle Logo" },
  { src: resultCompany04, alt: "FOSSIL Logo" },
  { src: resultCompany05, alt: "TOKYO METROPOLITAN GOVERNMENT Logo" },
  { src: resultCompany06, alt: "ANA Logo" },
  { src: resultCompany07, alt: "TATUNG Logo" },
  { src: resultCompany08, alt: "YANMAR Logo" },
]

const supportListItems = supportLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultSupportItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

const ecLogos = [
  { src: resultCompany09, alt: "Belle Maison Logo" },
  { src: resultCompany10, alt: "Daimaru Matsuzakaya Department Stores Logo" },
  { src: resultCompany11, alt: "Kohnan Logo" },
  { src: resultCompany12, alt: "HIMARAYA Logo" },
  { src: resultCompany13, alt: "AOYAMA TAILOR Logo" },
  { src: resultCompany14, alt: "SUIT SQUARE Logo" },
  { src: resultCompany15, alt: "hare:kari AOYAMA Logo" },
  { src: resultCompany16, alt: "SHIMA SEIKI Logo" },
]

// ロゴとaltテキストを使用してリストアイテムを生成
const ecListItems = ecLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultEcItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

const KoreaTopPage: React.VFC = () => {
  return (
    <>
      <SEO
        title="韓国進出・越境ECなら株式会社TAM｜日本企業の韓国マーケティング・Eコマース戦略支援"
        description="日本企業の韓国市場進出を一貫してサポート。市場リサーチ、ブランド構築、SNSプロモーション、クリエイティブ制作、SEO・広告運用まで、韓国の市場とユーザーの理解に基づいた戦略で成功を目指します。"
        image="https://oh-tam.com/korea/ogp_koreajp_jp.jpg"
        url="https://oh-tam.com/koreajp_jp"
        canonical="https://oh-tam.com/koreajp_jp"
        keywords="日本企業, 韓国進出, マーケティング, Eコマース, 市場リサーチ, ブランド構築, SNSプロモーション, クリエイティブ制作, SEO, 広告運用"
      />
      <KoreaJpLayout language="jp">
        {/* FV */}
        <section className={Styles.topJumbotron} id={Styles.topJumbotron}>
          <div className={Styles.topJumbotronInner}>
            <div className={Styles.topJumbotronHeadingWrapper}>
              <h1 className={Styles.topJumbotronHeading}>
                <picture>
                  <source media="(max-width: 767px)" srcSet={tamKoreaLogoSp} />
                  <img
                    src={tamKoreaLogo}
                    alt="Let's start marketing to Korea with TAMCHANG"
                    width="414"
                    height="76"
                  />
                </picture>
              </h1>
              <div className={Styles.topJumbotronTextWrapper}>
                <p className={Styles.topJumbotronSubTitle}>
                  韓国市場への進出支援
                </p>
                <p className={Styles.topJumbotronText}>
                  マーケティング・越境E-コマースを
                  <br />
                  一貫でサポート！
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Message */}
        <section className={Styles.topMessage} id={Styles.topMessage}>
          <div className={Styles.topMessageInner}>
            <div className={Styles.topMessageContainer}>
              <h2
                className={`${Styles.topMessageHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.en}>Message</span>
              </h2>
              <p className={Styles.topMessageSubtitle}>
                韓国市場への進出を目指すなら、
                <br />
                ぜひ TAMCHANG へご相談ください。
              </p>
              <p className={`${Styles.topMessageText} ${Styles.autoPhrase}`}>
                韓国現地や日韓関係に詳しいプロジェクトメンバーが市場調査やWEBサイト・Eコマースサイト立ち上げ、プロモーション、ブランド認知を全面サポートします。
              </p>
              <p className={`${Styles.topMessageText} ${Styles.autoPhrase}`}>
                韓国の消費者やビジネス需要に合致した最適なプランを提案・実施します。あなたのビジネスを韓国市場で飛躍させましょう！
              </p>

              <div className={Styles.topMessageLinkWrapper}>
                <a
                  href="https://www.tam-tam.co.jp/"
                  className={Styles.topMessageLink}
                  target="_blank"
                >
                  所属するTAMグループについてはこちら
                  <img
                    src={arrow}
                    alt="arrow"
                    width="11"
                    height="11"
                    className={Styles.topMessageLinkArrow}
                  />
                </a>
              </div>
            </div>
            <div className={Styles.topMessageImageWrapper}>
              <picture>
                <source media="(max-width: 767px)" srcSet={messageImg01Spw} />
                <source media="(max-width: 767px)" srcSet={messageImg01Sp} />
                <source srcSet={messageImg01w} />
                <img
                  src={messageImg01}
                  alt="TAM member"
                  width="691"
                  height="537"
                  className={Styles.topMessageImage}
                />
              </picture>
            </div>
          </div>
        </section>

        {/* Market */}
        <section className={Styles.topMarket} id={Styles.topMarket}>
          <div className={Styles.topMarketInner}>
            <div className={Styles.topMarketHeadingWrapper}>
              <h2
                className={`${Styles.topMarketHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.en}>Market</span>
              </h2>
              <p className={Styles.topMarketSubtitle}>
                韓国向けのビジネスは、
                <br className={Styles.spOnly} />
                チャンスがたくさん!
              </p>
            </div>
            <ul className={Styles.topMarketList}>
              <li className={Styles.topMarketItem}>
                <div className={Styles.topMarketDataContainer}>
                  <h3 className={Styles.topMarketHeadingLv3}>
                    01
                    <span>To Customer</span>
                  </h3>
                  <p className={Styles.topMarketItemSubtitle}>
                    日韓関係はビジネスも個人も
                    <br className={Styles.spOnly} />
                    活性傾向
                  </p>
                  <ul className={Styles.topMarketDataList}>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        日本への旅行客は韓国が
                        <br className={Styles.spOnly} />
                        1番多く、2019年比47%増
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <img
                          src={marketGraph01}
                          alt="Foreign Tourist Statistic"
                          width="335"
                          height="152"
                          className={Styles.topMarketDataItemImg}
                        />
                        <p className={Styles.topMarketDataItemRef}>
                          出典:JNTO「2024年2月訪日外客/2024年1-2月累計」
                        </p>
                      </div>
                    </li>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        日本にとって韓国は第5位、
                        <br />
                        韓国にとって日本は
                        <br className={Styles.pcOnly} />
                        第4位の貿易相手国・地域
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <picture>
                          <source
                            media="(max-width: 767px)"
                            srcSet={marketTable01w}
                          />
                          <img
                            src={marketTable01}
                            alt="탐그룹"
                            width="270"
                            height="109"
                            className={Styles.topMarketDataItemImg}
                          />
                        </picture>
                        <p className={Styles.topMarketDataItemRef}>
                          出典:外務省HP記載「大韓民国基礎データ」
                          <br className={Styles.spOnly} />
                          2024年3月14日時点
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className={Styles.topMarketItem}>
                <div className={Styles.topMarketDataContainer}>
                  <h3 className={Styles.topMarketHeadingLv3}>
                    02
                    <span>To Business</span>
                  </h3>
                  <p className={Styles.topMarketItemSubtitle}>
                    日本企業は韓国市場への
                    <br className={Styles.spOnly} />
                    進出に積極的
                  </p>
                  <ul className={Styles.topMarketDataList}>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        海外進出日系企業の
                        <br />
                        営業利益で２年連続首位
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <StaticImage
                          placeholder="none"
                          alt="1位:韓国 85.5% 2位:オーストラリア 81.8% 3位:台湾 79.2%"
                          src="../../images/korea/market/graph02_koreajpjp.png"
                          width={362}
                          height={152}
                          className={Styles.topMarketDataItemImg}
                        />
                        <p className={Styles.topMarketDataItemRef}>
                          出典：JETRO「2022年度
                          海外進出日系企業実態調査｜韓国編」
                        </p>
                      </div>
                    </li>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        ７割以上の日経企業が
                        <br />
                        韓国での販売機能を拡大予定
                      </p>
                      <div className={Styles.topMarketDataItemNumWrapper}>
                        <div className={Styles.topMarketDataItemNum}>
                          74<span className={Styles.dot}>.</span>4
                          <span className={Styles.percent}>%</span>
                        </div>
                        <p className={Styles.topMarketDataItemRef}>
                          出典：JETRO「2022年度
                          海外進出日系企業実態調査｜韓国編」
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </section>

        {/* Company */}
        <section className={Styles.topCompany} id="topCompany">
          <div className={Styles.topCompanyInner}>
            <h2
              className={`${Styles.topCompanyHeading} ${Styles.topHeadingLv2}`}
            >
              <picture>
                <source media="(max-width: 767px)" srcSet={companyHeadingSp} />
                <img
                  src={companyHeading}
                  alt="탐그룹"
                  width="464"
                  height="181"
                  className={Styles.topCompanyHeadingImage}
                />
              </picture>
              <span className={Styles.en}>
                <span className={Styles.isSmall}>Global</span>Company
              </span>
            </h2>
            <div className={Styles.topCompanyImageWrapper}>
              <picture className={Styles.topCompanyPic}>
                <source media="(max-width: 767px)" srcSet={companyImgSp} />
                <img
                  src={companyImg}
                  alt="A map showing the locations of the company's offices scattered across the world. Marks are placed in Tokyo and Osaka as central points, with additional offices in Amsterdam, London, Taiwan, Singapore, San Francisco, and Hawaii."
                  width="938"
                  height="494"
                  className={Styles.topCompanyImage}
                />
              </picture>
            </div>
          </div>
        </section>

        <section className={Styles.topPlan} id="topPlan">
          <TabsComponent />
          <div className={Styles.topService} id="topService">
            <div className={Styles.topServiceInner}>
              <h2
                className={`${Styles.topServiceHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.en}>
                  Service
                  <br className={Styles.spOnly} /> Menu
                </span>
              </h2>
              <p className={Styles.topServiceSubtitle}>
                進出プランに合わせて、
                <br className={Styles.spOnly} />
                弊社や現地・日韓関係に精通したパートナーと
                <br /> 協力体制を組み支援します。
              </p>
              <ul className={Styles.topServiceList}>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemResearch}`}
                >
                  <img
                    src={ServiceIcon01}
                    alt="research"
                    width="78"
                    height="78"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>韓国市場調査</p>
                  <p className={Styles.topServiceItemText}>
                    韓国現地リサーチや日韓情報に精通したメンバーと協力しリサーチ。
                    <span>韓国市場の可能性やユーザーの行動・嗜好</span>
                    韓国市場の可能性やユーザーの行動・嗜好などを
                    調査いたします。
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemStrategy}`}
                >
                  <img
                    src={ServiceIcon02}
                    alt="strategy"
                    width="60"
                    height="73"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    ブランド構築・戦略
                  </p>
                  <p className={Styles.topServiceItemText}>
                    韓国のユーザーの興味関心に合わせた
                    <span>
                      マーケティング/クリエイティブプランを立案、実行します。
                    </span>
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemSns}`}
                >
                  <img
                    src={ServiceIcon03}
                    alt="sns"
                    width="70"
                    height="79"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    SNS・プロモーション
                  </p>
                  <p className={Styles.topServiceItemText}>
                    Naver、kakao、Instagram、TikTokなどターゲットに合わせたプラットフォームや
                    ソーシャルメディアを活用し
                    <span>戦略立案から制作、運用までサポートします。</span>
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemCreate}`}
                >
                  <img
                    src={ServiceIcon04}
                    alt="create"
                    width="71"
                    height="71"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    クリエイティブ制作・サイト構築
                  </p>
                  <p className={Styles.topServiceItemText}>
                    <span>マーケティング戦略に基づいた制作</span>
                    を行います。企画からデザイン・ライティングまで一貫してできるのが強みです。
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemInfluencer}`}
                >
                  <img
                    src={ServiceIcon05}
                    alt="influencer"
                    width="81"
                    height="58"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    SEO・広告設計・運用
                  </p>
                  <p className={Styles.topServiceItemText}>
                    <span>
                      韓国の各広告プラットフォームの特性とターゲットユーザーの行動に合わせて、SEOや広告コンテンツの制作・運用
                    </span>
                    を実施します。
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemAdvertise}`}
                >
                  <img
                    src={ServiceIcon06}
                    alt="advertise"
                    width="70"
                    height="60"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>現地法人設立</p>
                  <p className={Styles.topServiceItemText}>
                    御社の進出プランに合わせ、
                    <span>日韓や会社設立に詳しい事務所様をご紹介します。</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className={Styles.topResult} id="topResult">
          <div className={Styles.topResultInner}>
            <div className={Styles.topResultBgWhite}>
              <div className={Styles.topResultBgWhiteContainer}>
                <h2
                  className={`${Styles.topResultHeading} ${Styles.topHeadingLv2}`}
                >
                  <span className={Styles.en}>
                    <span className={Styles.isSmall}>Support</span>
                    <span>results</span>
                  </span>
                </h2>
                <p className={Styles.topResultSubTitle}>
                  他にも、ご要望やビジネスの状況に合わせてプランをカスタマイズいたします!
                </p>
                <div className={Styles.topResultSupportContainer}>
                  <div className={Styles.topResultSupportTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4}`}
                    >
                      TAM 支援実績
                    </h3>
                    <p className={Styles.topResultSupportText}>
                      TAMグループがパートナーとしてマーケティングを支援した企業様の一部をご紹介。
                    </p>
                  </div>
                  <ul className={Styles.topResultSupportList}>
                    {supportListItems}
                  </ul>
                </div>
                <div className={Styles.topResultEcContainer}>
                  <div className={Styles.topResultEcTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4}`}
                    >
                      E-コマース 支援実績
                    </h3>
                    <p className={Styles.topResultEcText}>
                      今までパートナーとしてEコマースで支援した企業様の一部をご紹介。
                    </p>
                  </div>
                  <div className={Styles.topResultEcListContainer}>
                    <ul className={Styles.topResultEcList}>{ecListItems}</ul>
                    <ul className={Styles.topResultEcList}>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany17}
                          alt={"INE Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany18}
                          alt={"Heart Plus Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany19}
                          alt={"MEDICUS SHUPPAN,Publishers Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.spOnly}`}
                      >
                        <img
                          src={resultCompany20}
                          alt={"BRUNCH PARK Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.spOnly}`}
                      >
                        <img
                          src={resultCompany21}
                          alt={"BABBI Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                    </ul>
                    <ul className={Styles.topResultEcList}>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.pcOnly}`}
                      >
                        <img
                          src={resultCompany20}
                          alt={"BRUNCH PARK Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.pcOnly}`}
                      >
                        <img
                          src={resultCompany21}
                          alt={"BABBI Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <p className={Styles.topResultCaution}>
                  ※株式会社TAMおよびグループ会社全体の支援実績です。
                  <br className={Styles.spOnly} />
                  {"　"}海外および韓国進出実績ではありません。
                </p>
              </div>
            </div>
          </div>
        </section>
        <KoreaJpForm language="jp" />
        <BannerComponent />
      </KoreaJpLayout>
    </>
  )
}

export default KoreaTopPage
